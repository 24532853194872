import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Android, IPhone } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>
  Trailguide is a progressive web app (PWA) and cannot be found on App Store or
  Google Play. But you can still install Trailguide as an app in just a few
  seconds.
    </p>
    <br />
    <p><a parentName="p" {...{
        "href": "#safari"
      }}>{`Installing on iPhone using Safari`}</a></p>
    <p><a parentName="p" {...{
        "href": "#android"
      }}>{`Installing on Android`}</a></p>
    <br />
    <p><a parentName="p" {...{
        "href": "/howto/gps-on-iphone"
      }}>{`How do I get the GPS working on iPhone?`}</a></p>
    <p><a parentName="p" {...{
        "href": "/howto/navigation"
      }}>{`How do I use Trailguide for navigation?`}</a></p>
    <br />
    <a id="safari" />
    <h3>{`Installing Trailguide on an iPhone/iPad using Safari`}</h3>
    <br />
    <div className="flex flex-wrap justify-left">
  <IPhone alt="Open trailguide.net in Safari" img="/howto/install-iphone-1.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="1) Using Safari, open trailguide.net and click the middle icon at the bottom of the screen" mdxType="IPhone" />
  <IPhone alt="Slide the box upwards" img="/howto/install-iphone-2.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="2) Slide the box upwards to find the Add to Home Screen option" mdxType="IPhone" />
  <IPhone alt="Click the Add to Home Screen menu item" img="/howto/install-iphone-3.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="3) Click the Add to Home Screen menu item" mdxType="IPhone" />
  <IPhone alt="Click the Add label in the top right corner" img="/howto/install-iphone-4.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="4) Click the Add label in the top right corner" mdxType="IPhone" />
  <IPhone alt="Voilà!" img="/howto/install-iphone-5.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="5) Voilà! Trailguide is installed as an app" mdxType="IPhone" />
  <IPhone alt="Open as a fullscreen app" img="/howto/install-iphone-6.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="6) Trailguide now opens as a fullscreen app" mdxType="IPhone" />
    </div>
    <br />
    <br />
    <a id="android" />
    <h3>{`Installing Trailguide on an Android phone`}</h3>
    <br />
    <div className="flex flex-wrap justify-left">
  <Android alt="Open trailguide.net in Chrome" img="/howto/install-android-1.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="1) Using Chrome, open trailguide.net and click the three dots in the top right corner" mdxType="Android" />
  <Android alt="Click the Install app menu item" img="/howto/install-android-2.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="2) Click the Install app menu item" mdxType="Android" />
  <Android alt="Click the Install button" img="/howto/install-android-3.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="3) Click the Install button" mdxType="Android" />
  <Android alt="Close Chrome and check your home screen" img="/howto/install-android-4.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="4) Close Chrome and check your home screen" mdxType="Android" />
  <Android alt="Voilà!" img="/howto/install-android-5.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="5) Voilà! Trailguide is installed as an app" mdxType="Android" />
  <Android alt="Open as a fullscreen app" img="/howto/install-android-6.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="6) Trailguide now opens as a fullscreen app" mdxType="Android" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      